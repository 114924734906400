import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PrivateRoute from "./PrivateRoute/PrivateRoutes";
import AdminAddCourse from "./Component/Admin/AdminAddCourse";
import AdminManageCourse from "./Component/Admin/AdminManageCourse";
import AdminCoursePreview from "./Component/Admin/AdmincoursePreview";
import UserExploreAllCourse from "./Component/User/UserExploreAllcourse";
import CourseLibrary from "./Component/User/UserCourseLibrary";
import SignUp from "./Component/SignUp";
import SignIn from "./Component/SignIn";
import Home from "./Component/Home";
import MasterPage from "./Component/Master";
import MasterActivityPage from "./Component/MasterActivity";
import UserCoursePreview from "./Component/User/UserPreviewCourses";
import UserCourseSelection from "./Component/User/UserCourseSelection";
import UserMyCourses from "./Component/User/UserMyCourse";
import Cart from "./Component/User/cart";
import EditProfilePage from "./Common/edtProfile";
import CheckoutForm from "./Component/User/payment";
import TermsAndConditions from "./Component/User/termsAndCondition";
import PrivacyPolicy from "./Component/User/privacyPolicy";
import ResetPassword from "./Component/ResetPassword";

const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHER_KEY}`);

const MainRouter = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/Login" element={<SignIn />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/EditProfile" element={<PrivateRoute element={<EditProfilePage />} role={['User', 'Master', 'admin']} fallbackPath="/Login" />} />

          <Route path="/Master" element={<PrivateRoute element={<MasterPage />} role={['Master']} fallbackPath="/Login" />} />

          <Route path="/MasterActivity" element={ <PrivateRoute   element={<MasterActivityPage />} role={['Master', 'admin']} fallbackPath="/Login"/>}/>

          <Route path="/Admin" element={<PrivateRoute element={<AdminAddCourse />} role={['admin', 'Master']} fallbackPath="/Login" />} />

          <Route path="/Admin/:courseId" element={<PrivateRoute element={<AdminAddCourse />} role={['admin', 'Master']} fallbackPath="/Login" />} />

          <Route path="/AdminManageCourse" element={<PrivateRoute element={<AdminManageCourse />} role={['admin', 'Master']} fallbackPath="/Login" />} />

          <Route path="/AdminCoursePreview/:courseId" element={<PrivateRoute element={<AdminCoursePreview />} role={['admin', 'Master']} fallbackPath="/Login" />} />


          <Route path="/CourseLibrary" element={<PrivateRoute element={<CourseLibrary />} role={['User']} fallbackPath="/Login" />} />
          <Route path="/UserCoursePreview/:courseId" element={<PrivateRoute element={<UserCoursePreview />} role={['User']} fallbackPath="/Login" />} />
          <Route path="/UserCourseSelection" element={<PrivateRoute element={<UserCourseSelection />} role={['User']} fallbackPath="/Login" />} />
          <Route path="/cart" element={<PrivateRoute element={<Cart />} role={['User']} fallbackPath="/Login" />} />


          <Route path="/StripePayment/:clientSecret" element={<StripePayment />} />


          <Route path="/UserMyCourse" element={<PrivateRoute element={<UserMyCourses />} role={['User']} fallbackPath="/Login" />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>

          <Route path="/privacy" element={<PrivacyPolicy />}/>

          <Route path="/verify/action" element={<ResetPassword/>} />
        </Routes>
      </div>
    </Router>
  );
};

const StripePayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default MainRouter;
