import React, { useState, useEffect } from "react";
import { auth, firestore,resetPassword } from "../Common/firebase";
import { signInWithGoogle } from "../Common/firebase";
import Swal from "sweetalert2";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import apiService from "../services/api.service";
import UserHeader from "../Component/User/UserHeader";
import AdminHeader from "../Component/Admin/AdminHeader";
import '../css/EditProfile.css';

const EditProfilePage = () => {
  const [newEmail, setNewEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [contactOption, setContactOption] = useState("");
  const [newContactOption, setNewContactOption] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const emailID = localStorage.getItem("email");
  const [userName, setUserName] = useState("")
  const [cartCount, setCartCount] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userNameNew, setUserNameNew] = useState("")

  const hasPasswordProvider =
    auth.currentUser &&
    auth.currentUser.providerData.some(
      (provider) => provider.providerId === "password"
    );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoadingUser(false);
        const isGoogle = user.providerData.some(
          (provider) => provider.providerId === "google.com"
        );
        setIsGoogleUser(isGoogle);
      } else {
        setLoadingUser(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!loadingUser) {
      const checkEmailVerification = async () => {
        const user = auth.currentUser;
        if (user) {
          const { emailVerified } = user;
          setIsEmailVerified(emailVerified);
        }
      };
      checkEmailVerification();
    }
  }, [loadingUser]);

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const response = await apiService.getCart(userId);
        if (response && response?.data?.items) {
          setCartCount(response?.data?.items.length);
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    fetchCartCount();
  }, [userId]);

  useEffect(() => {
    const fetchUserContactOption = async () => {
      try {
        const userDoc = await firestore.collection("users").doc(userId).get();
        const userData = userDoc.data();
        if (userData && userData.contactOption) {
          setContactOption(userData.contactOption);
        }
        setUserName(userData.userName);
      } catch (error) {
        console.error("Error fetching user contact option:", error.message);
      }
    };

    fetchUserContactOption();
  }, [userId]);

  useEffect(() => {
    if (contactOption) {
      setNewContactOption(contactOption);
    }
  }, [contactOption]);

  useEffect(() => {
    if (newContactOption) {
      setContactOption(newContactOption);
      updateContactOptionInFirestore(newContactOption);
    }
  }, [newContactOption]);

  const updateContactOptionInFirestore = async (option) => {
    try {
      await firestore.collection("users").doc(userId).update({
        contactOption: option,
      });
    } catch (error) {
      console.error("Error updating user contact option in Firestore:", error);
      throw error;
    }
  };

  const disconnectGoogleAccount = async () => {
    try {
      const user = auth.currentUser;
      await user.unlink("google.com");
      Swal.fire({
        title: "Google Account Disconnected",
        text: "Your Google account has been successfully disconnected.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error("Error disconnecting Google account:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleChangePassword = async () => {
    try {
      if(newPassword === "" || confirmNewPassword === ""){
        Swal.fire({
          title: "Error",
          text: "Password must not be empty",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return
      }
      if (newPassword !== confirmNewPassword) {
        Swal.fire({
          title: "Error",
          text: "New password and confirm password do not match.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return
      }

      const user = auth.currentUser;
      if (!user) {
        Swal.fire({
          title: "Error",
          text: "User not signed in.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return
      }

      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );
      await user.reauthenticateWithCredential(credential);

      await user.updatePassword(newPassword);
      Swal.fire({
        title: "Password Changed",
        text: "Your password has been successfully updated.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Old password is incorrect. Please make sure you've entered the correct password and try again.",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleEmailChange = async () => {
    try {
      if (!newEmail) {
        throw new Error("Please enter a new email address.");
      }
      const confirmChange = await Swal.fire({
        title: "Confirm Email Change",
        text: "Are you sure you want to change your email address?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it",
        cancelButtonText: "Cancel",
      });

      const usersRef = firestore.collection('users');
      const emailQuerySnapshot = await usersRef.where('email', '==', newEmail).get();

      if (!emailQuerySnapshot.empty) {
        Swal.fire({
          title: "Error",
          text: "The email address is already in use.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        throw new Error("The email address is already in use.");
      }

      if (confirmChange.isConfirmed) {
        const provider = new firebase.auth.GoogleAuthProvider();
          const user = await signInWithGoogle();
          const { uid } = user;
          if(user.email !== newEmail){
            Swal.fire({
              title: "Error",
              text: "Please sign in with your entered email address",
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            return;
          }
          setLoading(true)
        await apiService
          .updateEmailAddress(uid, newEmail, userId)
          .then((result) => {
            setLoading(false)
            Swal.fire({
              title: "Email Change Requested",
              text: "Email updated successfully. You will now be logged out.",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
              auth.signOut();
              localStorage.removeItem("userRole");
              localStorage.removeItem("email");
              navigate(`/login`);
            });
          });
      }
    } catch (error) {
      console.error("Error updating email:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSaveName = async () => {
    try {
      if (!userNameNew) {
        throw new Error("Please enter a user name");
      }
      const confirmChange = await Swal.fire({
        title: "Confirm add name",
        text: "Are you sure this is the name you want to see on your certificate(s)? To safeguard certificate validity, this name can be saved only one time for each account. The user can not change it once it is saved.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it",
        cancelButtonText: "Cancel",
      });

      if (confirmChange.isConfirmed) {
        await apiService
          .updateUserName(userNameNew, userId)
          .then((result) => {
            setUserName(userNameNew)
            Swal.fire({
              title: "User Name Add Requested",
              text: "User name added successfully.",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })
          });
      }
    } catch (error) {
      console.error("Error adding username:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      await resetPassword(emailID);
      Swal.fire({
        title: "Password Reset Email Sent",
        text: `We sent an email to ${emailID} with a link to reset your password`,
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-left",
          confirmButton: "text-left",
        },
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error sending password reset email. Please try again.",
        icon: "error",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-left",
          confirmButton: "text-center",
        },
      });
    }
  };
  const handleChangePreference = async () => {
    try {
      await updateContactOptionInFirestore(newContactOption);
      setContactOption(newContactOption);
      Swal.fire({
        title: "Contact Preference Changed",
        text: "Your contact preference has been successfully updated.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating contact preference:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleContactOptionChange = (e) => {
    setNewContactOption(e.target.value);
  };

  return (
    <>
      <div>
        {userRole === "user" || userRole === "User" ? (
          <UserHeader className="mb-4" cartCount={cartCount} />
        ) : (
          <AdminHeader className="mb-4" />
        )}
        <br />
        <Container>
          <br />
          <Row>
            <Col sm={10}>
              <h3>Edit Profile</h3>
            </Col>
          </Row>
          {!loadingUser && (
            <Row className="mb-4">
              <Col sm={12} className="mb-4">
                <Card className="mt-4">
                  <Card.Body className="card-body">
                    <Form>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={12} className="mb-4">
                          <h4>USER DETAILS :</h4>
                        </Col>
                        <Form.Label column sm={4}>
                          Current Email Address:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue={emailID}
                          />
                        </Col>
                      </Form.Group>
                      {/* <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                          New Email Address:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                      {/* {!isEmailVerified && (
                    <p style={{ color: "red" }}>Please verify your email after changing it.</p>
                  )} */}
                      {/* <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 8, offset: 4 }}>
                          <Button onClick={handleEmailChange}>
                            CHANGE EMAIL ADDRESS
                          </Button>
                        </Col>
                      </Form.Group> */}
                      <Form.Group as={Row} className="mb-3 margin-top-text">
                        <Col sm={{ span: 8, offset: 4 }} className="mt-6">
                          <span>
                            In the following field, enter your name as you would
                            like to see it on any certificate. THIS CAN ONLY BE
                            ENTERED ONCE AND CAN NOT BE CHANGED ONCE ENTERED. If
                            you do not enter the information now, you will be
                            required to enter it prior to downloading a
                            certificate.
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                          Name on Certificate(s)
                        </Form.Label>
                        {userName ? (
                          <Col sm={8}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={userName}
                            />
                          </Col>
                        ) : (
                          <>
                            <Col sm={3}>
                              <Form.Control
                                type="text"
                                value={userNameNew}
                                onChange={(e) => setUserNameNew(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Button onClick={handleSaveName}>Save</Button>
                            </Col>
                          </>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 margin-top-text">
                        <Col sm={4} className="mb-4">
                          <h4>CONTACT OPTIONS :</h4>
                        </Col>
                        <Col sm={8} className="mb-4">
                          <span>
                            Please select your email preference options. The
                            Operations Science Institute may send emails once or
                            twice a month with articles and information updates
                            about developments in operations science.
                          </span>
                          <br />
                          <br />
                          <span>
                            All options put you on the email list except for the
                            last option, "Do not send me any emails."
                          </span>
                        </Col>
                        <Form.Label column sm={4}>
                          Please select your email preference options:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Select
                            value={newContactOption}
                            onChange={handleContactOptionChange}
                          >
                            <option value="Please contact me immediately">
                              Please contact me immediately
                            </option>
                            <option value="I will continue to follow the OSI. No need to contact me now">
                              I will continue to follow the OSI. No need to
                              contact me now
                            </option>
                            <option value="Contact me in a couple of months to discuss operations science">
                              Contact me in a couple of months to discuss
                              operations science
                            </option>
                            <option value="Do not send me any emails">
                              Do not send me any emails
                            </option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 8, offset: 4 }}>
                          <Button onClick={handleChangePreference}>
                            CHANGE PREFERENCE
                          </Button>
                        </Col>
                      </Form.Group>
                      {/* {isGoogleUser && (
                      <>
                        <p>
                          It looks like you are currently signed in with your
                          Google account. If you want to set a password for your
                          account within this application, you need to
                          disconnect your Google account first.
                        </p>
                        <Button onClick={disconnectGoogleAccount}>
                          Disconnect Google Account
                        </Button>
                      </>
                    )} */}

                      <Col sm={12} className="mb-4">
                        <h4>CHANGE PASSWORD :</h4>
                      </Col>
                      <Form.Group as={Row} className="mb-3 margin-top-text">
                        <Col sm={{ span: 8, offset: 4 }} className="mt-6">
                          <span>
                            Enter new and old password below. <br /> Password are
                            required to have at least: <br />8 characters, one
                            uppercase and one lowercase letter, one of the
                            following characters !@#$%^&*()
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                          Current Password:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                          New Password:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                          Confirm New Password:
                        </Form.Label>
                        <Col sm={8} className="mb-4">
                          <Form.Control
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                          />
                        </Col>
                        {/* {hasPasswordProvider && ( */}
                        <div className="center-align-container mb-4">
                          <div className="forgot-password">
                            <span onClick={handleForgotPassword}>
                              Forgot Password?
                            </span>
                          </div>
                        </div>
                        {/* )} */}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 8, offset: 4 }}>
                          <Button onClick={handleChangePassword}>
                            CHANGE PASSWORD
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </>
  );
};

export default EditProfilePage;
