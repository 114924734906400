// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-table tbody tr:hover {
    background-color: inherit !important;
    border-color: inherit !important;
  }
.CourseCheckIcon{
  text-align: center;
}
.CourseNotStarted
{
  background-color: #F0962B !important;
  color: rgb(255, 255, 255) !important;
}
.CourseStarted
{
  background-color: #70AD47 !important;
  color: rgb(255, 255, 255) !important;
}

.disabled-button-color{
  background-color: gray !important;
}

.no-bullets{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.center-download-button{
  text-align: center;
}

/* @media (max-width: 576px) {
  .course-table th, .course-table td {
     padding: 8px 4px;
   }
   th>h6{
     font-size: 11px;
   }
   .center-download-button>Button{
     font-size:9px;
   }
 }

 @media (max-width: 425px) {
   th>h6{
     font-size: 8px;
     font-weight: bolder;
   }
   th{
     padding: 0 !important;
   }
   .text-uppercase{
     font-size: 8px !important;
   }
   .course-table th, .course-table td {
     padding: 5px 5px;
   }
   .center-download-button>Button{
     font-size:9px;
   }
   .center-download-button{
     display: flex;
   }
 }

 @media (max-width: 320px) {

   th>h6{
     font-size: 7px;
   }
   .course-table th, .course-table td {
     padding: 3px 0px;
   }

   .center-download-button>Button{
     font-size:8px;
   }
   .center-download-button{
     display: flex;
   }
 } */
`, "",{"version":3,"sources":["webpack://./src/css/userMycourse.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gCAAgC;EAClC;AACF;EACE,kBAAkB;AACpB;AACA;;EAEE,oCAAoC;EACpC,oCAAoC;AACtC;AACA;;EAEE,oCAAoC;EACpC,oCAAoC;AACtC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAiDI","sourcesContent":[".course-table tbody tr:hover {\n    background-color: inherit !important;\n    border-color: inherit !important;\n  }\n.CourseCheckIcon{\n  text-align: center;\n}\n.CourseNotStarted\n{\n  background-color: #F0962B !important;\n  color: rgb(255, 255, 255) !important;\n}\n.CourseStarted\n{\n  background-color: #70AD47 !important;\n  color: rgb(255, 255, 255) !important;\n}\n\n.disabled-button-color{\n  background-color: gray !important;\n}\n\n.no-bullets{\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.center-download-button{\n  text-align: center;\n}\n\n/* @media (max-width: 576px) {\n  .course-table th, .course-table td {\n     padding: 8px 4px;\n   }\n   th>h6{\n     font-size: 11px;\n   }\n   .center-download-button>Button{\n     font-size:9px;\n   }\n }\n\n @media (max-width: 425px) {\n   th>h6{\n     font-size: 8px;\n     font-weight: bolder;\n   }\n   th{\n     padding: 0 !important;\n   }\n   .text-uppercase{\n     font-size: 8px !important;\n   }\n   .course-table th, .course-table td {\n     padding: 5px 5px;\n   }\n   .center-download-button>Button{\n     font-size:9px;\n   }\n   .center-download-button{\n     display: flex;\n   }\n }\n\n @media (max-width: 320px) {\n\n   th>h6{\n     font-size: 7px;\n   }\n   .course-table th, .course-table td {\n     padding: 3px 0px;\n   }\n\n   .center-download-button>Button{\n     font-size:8px;\n   }\n   .center-download-button{\n     display: flex;\n   }\n } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
