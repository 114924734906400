import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../../css/AdminHeader.css";
import { auth, firestore } from "../../Common/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";


const CustomNavbar = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(localStorage?.getItem("userRole"));
  const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const location =useLocation()

  const navbarStyle = {
    backgroundColor: "#70AD47",
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userDoc = await firestore
            .collection("users")
            .doc(currentUser.uid)
            .get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserRole(userData.role);
            localStorage.setItem("userRole", userData.role);
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error.message);
      }
    };

    fetchUserRole();
  }, []);

  const handleCoursesDropdownToggle = () => {
    setCoursesDropdownOpen(!coursesDropdownOpen);
  };

  const handleProfileDropdownToggle = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };
  const rightLinksStyle = {
    marginLeft: "auto",
    paddingRight: "8%",
  };
  const rightLinksLoginStyle={
    marginLeft: "80%",
  }
  const handleLogout = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Are you sure you want to logout?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        try {
          localStorage.removeItem("userRole");
          localStorage.removeItem("email");
          setUserRole(null);
          await auth.signOut();
          navigate(`/login`);
        } catch (error) {
          console.error("Error during logout:", error.message);
        }
      }
    } catch (error) {
      console.error("Error during logout:", error.message);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" style={navbarStyle} variant="dark">
      <Navbar.Brand>OPERATIONS SCIENCE INSTITUTE</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/Home">HOME</Nav.Link>
          {!userRole && location.pathname !== '/Login' && (
            <Nav.Link href={process.env.REACT_APP_WHY_OS} target="_blank" rel="noopener noreferrer"style={{ whiteSpace: 'nowrap' }}>WHY OS?</Nav.Link>
          )}
          {(userRole === "Admin" || userRole === "admin" || userRole === "Master") && (
            <NavDropdown
              title="COURSES"
              id="collasible-nav-dropdown-courses"
              show={coursesDropdownOpen}
              onMouseEnter={handleCoursesDropdownToggle}
              onMouseLeave={handleCoursesDropdownToggle}
            >
              <NavDropdown.Item href="/Admin">Add Course</NavDropdown.Item>
              <NavDropdown.Item href="/AdminManageCourse">Manage Course</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
        {!userRole && (
          <Nav className="ml-auto login-btn" style={rightLinksLoginStyle}>
            <Nav.Link href="/Login" className="Login">
              LOGIN
            </Nav.Link>
          </Nav>
        )}
        <Nav className="ml-auto" style={rightLinksStyle}>
          {userRole === "Master" && (
            <NavDropdown
              title="PROFILE"
              id="collasible-nav-dropdown-profile"
              show={profileDropdownOpen}
              onMouseEnter={handleProfileDropdownToggle}
              onMouseLeave={handleProfileDropdownToggle}
            >
              <NavDropdown.Item href="/EditProfile">Edit Profile</NavDropdown.Item>
              <NavDropdown.Item href="/Master">Manage User</NavDropdown.Item>
              <NavDropdown.Item href="/MasterActivity">User Activity</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {(userRole === "Admin" || userRole === "admin") && (
            <NavDropdown title="PROFILE" id="collasible-nav-dropdown-admin-profile">
              <NavDropdown.Item href="/EditProfile">Edit Profile</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
