import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import UserHeader from "../User/UserHeader";
import "../../css/AdminCoursePreview.css";
import "../../css/CourseLibrary.css";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";

const CourseLibrary = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [cartCount, setCartCount] = useState(0);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [durations, setDurations] = useState({})
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await apiService.getAllCourses();
        if (response && response.courses) {
          const publishedCourses = response.courses.filter(
            (course) => course.courseStatus === "publish"
          );
          const courseDurations = await Promise.all(
            publishedCourses.map(async (course, courseIndex) => {
              let courseSumTime = 0;
              const lessonSumTimes = await Promise.all(
                course.lessons.map(async (lesson, lessonIndex) => {
                  if (lesson.video) {
                    const vimeoId = extractVimeoId(lesson.video);
                    const response = await fetch(`${process.env.REACT_APP_VIMEO_TIME_FETCH_API}${vimeoId}`);
                    if(response.status === 404){
                      return 0;
                    }
                    const data = await response.json();
                    courseSumTime += data.duration;
                    return data.duration;
                  } else {
                    return 0;
                  }
                })
              );
              function sumOfElements(array) {
                let totalSum = 0;
                for (const element of array) {
                  totalSum += element;
                }
                return totalSum;
              }
              const courseDuration = sumOfElements(lessonSumTimes);

              return {
                courseIndex,
                courseDuration,
              };
            })
          );
          setDurations(courseDurations);

            const coursesWithDurations = await Promise.all(
            publishedCourses.map(async (course) => {
              const totalDuration = await calculateCourseDuration(course);
              return { ...course, duration: totalDuration };
            })
          );
          setCourses(coursesWithDurations);
        } else {
          console.error("Invalid API response structure.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const extractVimeoId = (videoUrl)=>{
    const videoId = videoUrl.split("?")[0];
    return videoId;
  }

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const purchasedResponse = await apiService.getPurchasedCourses(userId);
        if (purchasedResponse && purchasedResponse.data) {
          setPurchasedCourses(purchasedResponse.data);
          const cartResponse = await apiService.getCart(userId);
          if (cartResponse && cartResponse.data && cartResponse.data.items) {
            const cartItems = cartResponse.data.items;
            const filteredCartItems = cartItems.filter(
              (cartItem) =>
                !purchasedResponse.data.find(
                  (purchasedCourse) =>
                    purchasedCourse.courseId === cartItem.courseId
                )
            );
            setCartCount(filteredCartItems.length);
          }
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    if (userId) {
      fetchCartCount();
    }
  }, [userId]);

  const calculateCourseDuration = async (course) => {
    let totalDuration = 0;
    if (course.lessons && Array.isArray(course.lessons)) {
      for (const lesson of course.lessons) {
        if (lesson.video && Array.isArray(lesson.video)) {
          for (const videoUrl of lesson.video) {
            const duration = await getVideoDuration(videoUrl);
            totalDuration += duration;
          }
        }
      }
    }
    return totalDuration;
  };

  const getVideoDuration = async (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.addEventListener("loadedmetadata", () => {
        resolve(video.duration);
      });
      video.addEventListener("error", (error) => {
        console.error("Error loading video:", error);
        reject(error);
      });
      video.load();
    });
  };

  const handlenavigateManageCourse = () => {
    navigate(`/UserCourseSelection`);
  };

  const handleCourseClick = (courseId) => {
    navigate(`/UserCoursePreview/${courseId}`);
  };

  function secondsToTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const minutes = Math.floor(seconds / 60);

    return `${minutes} Minutes`;
  }

  return (
    <div className="CoursePreview">
    <UserHeader className="mb-4" cartCount={cartCount} />
    <br />
    <Container>
      <br />
      <Row>
        <Col sm={10}>
          <h3>OPERATIONS SCIENCE LIBRARY</h3>
          <span>
            Below is a table describing operations science online courses.
            Courses can only be selected for purchase from the Course Selection page.
          </span>
        </Col>
        <Col sm={2}>
          {" "}
          <Button onClick={handlenavigateManageCourse}>
            COURSE SELECTION
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {loading ? ( // Render loading indicator while fetching courses
            <div>Loading...</div>
          ) : (
            <Card className="mt-4">
              <Card.Body className="card-body">
                <Table striped bordered hover className="course-table">
                  <thead>
                    <tr>
                      <th>COURSE NAME</th>
                      <th></th>
                      <th>DESCRIPTION</th>
                      {/* <th>DURATION</th> */}
                      <th>PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                  {courses.length === 0 ? (
        <tr>
          <td colSpan="6">No courses available</td>
        </tr>
      ) : (
                    courses.map((course, index) => (
                      <tr key={course.id}>
                        <td className="courseTitleLink course-library-data">
                            {course.courseTitle}
                        </td>
                        <td><img className="img-course-library" src={course.thumbnailUrl}></img></td>
                        <td className="">{course.courseDescription} <br/> <br/>
                            Duration - {durations ? secondsToTime(durations[index].courseDuration) : <></>}, Lessons - {course.lessons.length} <br/> <br/>
                            Prerequisite - {course.prerequisites}
                        </td>
                        {/* <td>{ durations ? secondsToTime(durations[index].courseDuration)  : <></> }</td> */}
                        <td className="course-library-txt">
                          {course.coursePrice === 0
                            ? "Free"
                            : `$${course.coursePrice}`}
                        </td>
                      </tr>
                    ))
      )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <br/>
    </Container>
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    )}
  </div>
);
};

export default CourseLibrary;
