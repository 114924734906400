import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Table,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Button,
} from "react-bootstrap";
import AdminHeader from "./Admin/AdminHeader";
import "../css/MasterActivity.css";
import { firestore } from "../Common/firebase";
import apiService from "../services/api.service";

const SIX_WEEKS_IN_MS = 6 * 7 * 24 * 60 * 60 * 1000;

const MasterActivity = () => {
  const [users, setUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;

  useEffect(() => {
    fetchUsersWithCourses();
  }, []);

  // Function to schedule email reminders 24 hours before data is wiped out
  const scheduleEmailReminder = async (userEmail, lastSignInTime) => {
    const now = new Date();
    const lastSignInDate = userDataToDate(lastSignInTime);
    const timeDifference = now - lastSignInDate;
    const hoursLeft = (SIX_WEEKS_IN_MS - timeDifference) / (1000 * 60 * 60);

    // Send email if data is about to be wiped out within 24 hours
    if (hoursLeft <= 24 && hoursLeft > 0 && userEmail === process.env.REACT_APP_ADMIN_EMAIL) {
      try {
        await apiService.dataClearingMail(userEmail);
        console.log(`Email reminder sent to ${userEmail}`);
      } catch (error) {
        console.error(`Failed to send email reminder to ${userEmail}:`, error);
      }
    }
  };

  // Fetch users and their purchased courses
  const fetchUsersWithCourses = async () => {
    try {
      const usersSnapshot = await firestore.collection("users").get();
      const now = new Date();
      const rowsData = [];

      await Promise.all(
        usersSnapshot.docs.map(async (doc) => {
          const userData = doc.data();
          const coursesSnapshot = await firestore
            .collection("users")
            .doc(doc.id)
            .collection("purchasedCourses")
            .get();

          const purchasedCourses = await Promise.all(
            coursesSnapshot.docs.map(async (courseDoc) => {
              const courseData = courseDoc.data();
              const expirationDate = courseData.expirationDate
                ? new Date(courseData.expirationDate.seconds * 1000)
                : null;

              // Fetch course details from API for length
              let totalLessons = 0;
              try {
                const response = await apiService.getCourseById(
                  courseData.courseId
                );
                if (response?.data?.course) {
                  totalLessons = response.data.course.lessons.length;
                }
              } catch (error) {
                console.error("Error fetching course details:", error);
              }

              // Get the number of completed lessons from Firestore data
              const completedLessons = courseData.completedLesson || 0;

              // Mark the course as expired if the expiration date has passed
              if (expirationDate && now > expirationDate) {
                return {
                  ...courseData,
                  courseTitle: courseData.courseTitle, // Get title from Firestore directly
                  totalLessons,
                  completedLessons,
                  status: "Expired",
                };
              }

              return {
                ...courseData,
                courseTitle: courseData.courseTitle, // Get title from Firestore directly
                totalLessons,
                completedLessons,
                status: "Active",
              };
            })
          );

          // Check if user's data is about to be wiped out after 6 weeks
          const lastSignInDate = userDataToDate(userData.lastSignInTime);
          if (now - lastSignInDate > SIX_WEEKS_IN_MS) {
            console.log(
              `User ${userData.email} has not signed in for over 6 weeks.`
            );
          } else {
            // Schedule email reminder if data is about to be wiped out within 24 hours
            await scheduleEmailReminder(userData.email, userData.lastSignInTime);
          }

          if (purchasedCourses.length > 0) {
            purchasedCourses.forEach((course) => {
              rowsData.push({
                email: userData.email,
                lastSignInTime: userData.lastSignInTime,
                ...course,
                purchasedCoursesCount: purchasedCourses.length,
              });
            });
          } else {
            rowsData.push({
              email: userData.email,
              lastSignInTime: userData.lastSignInTime,
              courseTitle: "N/A",
              purchasedCoursesCount: 0,
            });
          }
        })
      );

      const sortedRows = rowsData.sort((a, b) => {
        const dateA = userDataToDate(a.lastSignInTime);
        const dateB = userDataToDate(b.lastSignInTime);
        return dateB - dateA;
      });

      setUsers(sortedRows);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Convert Firestore timestamp to Date
  const userDataToDate = (timestamp) => {
    if (!timestamp) return 0;
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000);
    }
    return new Date(timestamp);
  };

  // Handle search input change
  const handleSearchChange = (e) => setSearchEmail(e.target.value);

  // Filter rows based on search input
  const filteredRows = users.filter((row) =>
    row.email?.toLowerCase().includes(searchEmail.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate days left until expiration
  const calculateDaysLeft = (expirationDate) => {
    if (!expirationDate?.seconds) return "";
    const now = new Date();
    const expiration = new Date(expirationDate.seconds * 1000);
    return Math.max(0, Math.ceil((expiration - now) / (1000 * 60 * 60 * 24)));
  };

  // Format date for display
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = userDataToDate(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Export to Excel with timestamp in filename
  const exportToExcel = () => {
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-"); // Format: YYYY-MM-DDTHH-MM-SS
    const fileName = `user_activity_data_${timestamp}.xlsx`;

    const formattedData = users.map((row) => ({
      Email: row.email,
      "Last Signed In": formatDate(row.lastSignInTime),
      "Course Title": row.courseTitle,
      "Purchase Date": formatDate(row.purchaseDate),
      "Expiration Date": formatDate(row.expirationDate),
      "Days Left": calculateDaysLeft(row.expirationDate),
      Status: row.status || "N/A",
      "Completed Lessons":
        row.totalLessons == null || row.completedLessons == null
          ? "N/A"
          : row.completedLessons === 0
          ? `0 of ${row.totalLessons}`
          : `${row.completedLessons} of ${row.totalLessons}`,
      Score: row.purchasedCoursesCount === 0 ? "N/A" : row.score == null ? 0 : row.score,
      "Number of Purchased Courses":
        row.purchasedCoursesCount == null || row.purchasedCoursesCount === 0
          ? "N/A"
          : row.purchasedCoursesCount,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <AdminHeader />
      <Container className="mt-4">
        <Row className="mb-3">
          <Col>
            <h3 className="font-weight-bold text-uppercase">User Activity</h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}>
            <InputGroup>
              <FormControl
                placeholder="Search by email"
                value={searchEmail}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2}>
            <Button
              variant="success"
              onClick={exportToExcel}
              style={{ height: "63%", padding: "0.375rem 0.75rem" }}
            >
              Export to Excel
            </Button>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="custom-table" bordered hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Last Signed In</th>
                <th>Course Title</th>
                <th>Purchase Date</th>
                <th>Expiration Date</th>
                <th>Status</th>
                <th className="text-center">Days Left</th>
                <th className="text-center">Completed Lessons</th>
                <th>Score</th>
                <th className="text-center">Number of Purchased Courses</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr key={index}>
                  <td>{row.email}</td>
                  <td>
                    {row.lastSignInTime ? formatDate(row.lastSignInTime) : ""}
                  </td>
                  <td>{row.courseTitle}</td>
                  <td>
                    {row.purchaseDate ? formatDate(row.purchaseDate) : "N/A"}
                  </td>
                  <td>
                    {row.expirationDate
                      ? formatDate(row.expirationDate)
                      : "N/A"}
                  </td>
                  <td>{row.status}</td>
                  <td className="text-center">
                    {row.expirationDate
                      ? calculateDaysLeft(row.expirationDate)
                      : "N/A"}
                  </td>
                  <td className="text-center">
                    {row.totalLessons == null || row.completedLessons == null
                      ? "N/A"
                      : row.completedLessons === 0
                      ? `0 of ${row.totalLessons}`
                      : `${row.completedLessons} of ${row.totalLessons}`}
                  </td>

                  <td className="text-center">
                  {row.purchasedCoursesCount === 0 ? "N/A" : row.score == null ? 0 : row.score}
                  </td>
                  <td className="text-center">
                    {row.purchasedCoursesCount == null ||
                    row.purchasedCoursesCount === 0
                      ? "N/A"
                      : row.purchasedCoursesCount}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination>
          {[...Array(Math.ceil(filteredRows.length / rowsPerPage)).keys()].map(
            (page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => paginate(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </Container>
    </div>
  );
};

export default MasterActivity;
