import http from '../Common/http-common';
import axios from 'axios';
import handleApiError from '../utils/Errorhandler';
class apiService {
  saveAdminCourse(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/SaveAdminAddCourse`, data).catch(handleApiError);
  }
  getAllCourses() {
    return http.get('/getAllCourses').then(response => response.data).catch(handleApiError);
  }
  getCourseById(courseId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/courses/${courseId}`).catch(handleApiError);
  }
  previewCourseByVersion(courseId,version) {
    return axios.get(`${process.env.REACT_APP_API_URL}/previewCourseVersion/${courseId}/${version}`).catch(handleApiError);
  }
  updateCourseById(courseId, data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/updateCourseById/${courseId}`,data).catch(handleApiError);
  }
  deleteCourseById(courseId,version) {
    return http.delete(`/deleteCourseById/${courseId}/${version}`).catch(handleApiError);
  }
  deleteCourse(courseId) {
    return http.delete(`/deleteCourse/${courseId}`).catch(handleApiError);
  }
  deleteQuestionInCourseById(courseId,questionId) {
    return http.delete(`/deleteQuestionInCourseById/${courseId}/${questionId}`).catch(handleApiError);
  }
  getAllVersionForCourses(){
    return http.get('/getAllVersionForCourses').then(response => response.data).catch(handleApiError);
  }
  addToCart(userId, courseId) {
    return axios.post(`${process.env.REACT_APP_API_URL}/addToCart`, { userId, courseId }).catch(handleApiError);
  }

  getCart(userId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/getCart/${userId}`).catch(handleApiError);
  }

  removeFromCart(userId, courseId) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/removeFromCart/${userId}/${courseId}`).catch(handleApiError);
  }

  clearCart(userId) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/clearCart/${userId}`).catch(handleApiError);
  }
  purchaseCourse(userId, courseId,version) {
    return axios.post(`${process.env.REACT_APP_API_URL}/purchaseCourse`, { userId, courseId,version }).catch(handleApiError);
  }

  getPurchasedCourses(userId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/getPurchasedCourses/${userId}`).catch(handleApiError);
  }
  saveVideoProgress(userId, courseId, videoUrl, currentTime) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/saveVideoProgress`,
      { userId, courseId, videoUrl, currentTime }
    ).catch(handleApiError);
  }


  getVideoProgress(userId, courseId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/getVideoProgress/${userId}/${courseId}`).catch(handleApiError);
  }
  createPaymentIntent(amount, currency,description,username,userId, cartItems) {
    return axios.post(`${process.env.REACT_APP_API_URL}/createPaymentIntent`, { amount, currency,description,username,userId, cartItems }).catch(handleApiError);
  }

  getYoutubeVideo(){
    return axios.get(`${process.env.REACT_APP_API_URL}/auth/youtube`).catch(handleApiError);
  }

  getThumbNail(){
    return axios.get(`${process.env.REACT_APP_API_URL}/thumbnail`).catch(handleApiError);
  }

  getVideoDuration(videoId){
    return axios.get(`${process.env.REACT_APP_API_URL}/videoDuration/${videoId}`).catch(handleApiError);
  }

  lessonCompleted(userId, courseId){
    return axios.get(`${process.env.REACT_APP_API_URL}/lessonCompleted/${userId}/${courseId}`).catch(handleApiError);
  }

  submitEvaluation(userId, score, courseId) {
    return axios.post(`${process.env.REACT_APP_API_URL}/evaluationScore`, { userId, score, courseId}).catch(handleApiError);
  }

  updateEmailAddress(uid, newEmail, userId){
    return axios.post(`${process.env.REACT_APP_API_URL}/updateEmailAddress`, { uid, newEmail, userId }).catch(handleApiError);
  }

  updateUserName(userName, userId){
    return axios.get(`${process.env.REACT_APP_API_URL}/updateUserName/${userName}/${userId}`, { userName, userId }).catch(handleApiError);
  }

  courseExpired(courseId, userId, userEmail, courseTitle){
    return axios.post(`${process.env.REACT_APP_API_URL}/courseExpired`, { courseId, userId, userEmail, courseTitle }).catch(handleApiError);
  }

  evaluationCompleted(userId, courseId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/evaluationCompleted/${userId}/${courseId}`).catch(handleApiError);
  }

  deletePurchasedCourse(userId, courseId) {
    return axios.get(`${process.env.REACT_APP_API_URL}/deletePurchasedCourse/${userId}/${courseId}`).catch(handleApiError);
  }

  generateCertificateFlowLeadership(username) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/generate-certificate-flow-leadership`,
      { username },
      { responseType: 'blob' }
    ).catch(handleApiError);
  }

  generateCertificateInventory(username) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/generate-certificate-invcentory-leadership`,
      { username },
      { responseType: 'blob' }
    ).catch(handleApiError);
  }

  generateCertificateOperationScientist(username) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/generate-certificate-operation-science-leadership`,
      { username },
      { responseType: 'blob' }
    ).catch(handleApiError);
  }

  welcomeMail(userEmail) {
    return axios.get(`${process.env.REACT_APP_API_URL}/welcomeMail/${userEmail}`).catch(handleApiError);
  }
  dataClearingMail(userEmail) {
    return axios.get(`${process.env.REACT_APP_API_URL}/dataClearingMail/${userEmail}`).catch(handleApiError);
  }

  certificateEligibility(userId){
    return axios.get(`${process.env.REACT_APP_API_URL}/certificateEligibilityCart/${userId}`).catch(handleApiError);
  }

  getUsers(){
    return axios.get(`${process.env.REACT_APP_API_URL}/getUsers`).catch(handleApiError);
  }

  deleteUser(email){
    return axios.get(`${process.env.REACT_APP_API_URL}/deleteUser/${email}`).catch(handleApiError);
  }
}
export default new apiService();
