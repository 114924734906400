import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import UserHeader from "../User/UserHeader";
import "../../css/userMycourse.css";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import {
  faCircle,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { firestore } from "../../Common/firebase.js";
import Swal from "sweetalert2";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import { ReactComponent as Wrong } from "../../assets/wrong.svg";

const UserMyCourses = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const emailID = localStorage.getItem("email");
  const [cartCount, setCartCount] = useState(0);
  const [courseName, setcourseName] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [preventCourseSelection, setPreventCourseSelection] = useState(false);
  const [completedCourseId, setCompletedCourseId] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    fetchCartCount();
    fetchUserContactOption();
  }, [userId]);

  const fetchUserContactOption = async () => {
    try {
      const userDoc = await firestore.collection("users").doc(userId).get();
      const userData = userDoc.data();
      if (userData && userData.userName) {
        setUserName(userData.userName);
      }
    } catch (error) {
      console.error("Error fetching user contact option:", error.message);
    }
  };

  const fetchCartCount = async () => {
    try {
      const purchasedResponse = await apiService.getPurchasedCourses(userId);
      if (purchasedResponse && purchasedResponse.data) {
        setPurchasedCourses(purchasedResponse.data);
        const cartResponse = await apiService.getCart(userId);
        if (cartResponse && cartResponse.data && cartResponse.data.items) {
          const cartItems = cartResponse.data.items;
          const filteredCartItems = cartItems.filter(
            (cartItem) =>
              !purchasedResponse.data.find(
                (purchasedCourse) =>
                  purchasedCourse.courseId === cartItem.courseId
              )
          );
          setCartCount(filteredCartItems.length);
        }
      }
    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await apiService.getAllCourses();
        if (response && response.courses) {
          const publishedCourses = response.courses.filter(
            (course) =>
              course.courseStatus === "publish" ||
              course.courseStatus === "viewOnly"
          );
          const purchasedCoursesResponse = await apiService.getPurchasedCourses(
            userId
          );
          const purchasedCourses = purchasedCoursesResponse?.data || [];

          const updatedCourseNames = await Promise.all(
            publishedCourses.map(async (course) => {
              const purchasedCourse = purchasedCourses.find(
                (purchasedCourse) => purchasedCourse.courseId === course.id
              );

              const isPurchased = !!purchasedCourse;
              const score = isPurchased ? purchasedCourse.score : null;
              const evaluationCompleted = isPurchased
                ? purchasedCourse.evaluationCompleted
                : false;
              const completedLesson = isPurchased
                ? purchasedCourse.completedLesson || 0
                : 0;

              // Fetch totalLessons from the API (same as MasterActivity.js)
              let totalLessons = 0;
              try {
                const courseDetails = await apiService.getCourseById(course.id);
                if (courseDetails?.data?.course?.lessons) {
                  totalLessons = courseDetails.data.course.lessons.length;
                }
              } catch (error) {
                console.error("Error fetching course details:", error);
              }

              return {
                ...course,
                isPurchased,
                evaluationCompleted,
                score,
                completedLesson,
                totalLessons,
              };
            })
          );

          setcourseName(
            updatedCourseNames.filter(
              (course) =>
                course.courseStatus === "publish" ||
                (course.isPurchased && course.courseStatus === "viewOnly")
            )
          );
        } else {
          console.error("Invalid API response structure.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [userId]);

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const response = await apiService.getPurchasedCourses(userId);
        if (response && response?.data) {
          setCourses(response?.data);
        }
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };

    fetchPurchasedCourses();
  }, [userId]);

  const handlenavigateManageCourse = () => {
    navigate(`/UserCourseSelection`);
  };

  const handleCourseClick = (courseId) => {
    function getDocumentByCourseId(courseName, courseId) {
      return courseName.find((document) => document.id === courseId);
    }
    const foundDocument = getDocumentByCourseId(courseName, courseId);
    if (foundDocument) {
      if (foundDocument.prerequisites !== "None") {
        const str = foundDocument.prerequisites;
        const arr = str.split(",");
        let prerequisiteCourses = [];
        for (let i = 0; i < arr.length; i++) {
          const prerequisiteCourse = courseName.filter((courses) => {
            return courses.courseTitle === arr[i];
          });
          prerequisiteCourses.push(...prerequisiteCourse);
        }
        for (let i = 0; i < prerequisiteCourses.length; i++) {
          if (
            prerequisiteCourses[i].evaluationCompleted === false ||
            prerequisiteCourses[i].evaluationCompleted === undefined
          ) {
            toast(
              "Prerequisite course and its evaluation must be completed before starting the next course."
            );
            return;
          }
        }
        navigate(`/UserCoursePreview/${courseId}`);
      } else {
        navigate(`/UserCoursePreview/${courseId}`);
      }
    }
  };

  const [operationScienceFlowCertificate, setoperationScienceFlowCertificate] =
    useState(false);
  const [operationScienceInventory, setoperationScienceInventory] =
    useState(false);
  const [operationScientistCertificate, setoperationScientistCertificate] =
    useState(false);

  useEffect(() => {
    if (courseName.length > 0) {
      let completed_OPERATIONS_SCIENCE_FLOW = 0;
      let completed_OPERATIONS_SCIENCE_INVENTORY = 0;
      let completed_OPERATIONS_SCIENTIST = 0;
      let certificate_OPERATIONS_SCIENCE_FLOW_Total = 0;
      let certificate_OPERATIONS_SCIENCE_INVENTORY_Total = 0;
      let certificate_OPERATIONS_SCIENTIST_Total = 0;
      courseName.forEach((course) => {
        if (course?.certificates?.includes("FLOW LEADERSHIP")) {
          certificate_OPERATIONS_SCIENCE_FLOW_Total =
            certificate_OPERATIONS_SCIENCE_FLOW_Total + 1;
          if (
            course?.score !== "undefined" &&
            course.evaluationCompleted === true
          ) {
            completed_OPERATIONS_SCIENCE_FLOW =
              completed_OPERATIONS_SCIENCE_FLOW + 1;
          }
        }
        if (course?.certificates?.includes("INVENTORY LEADERSHIP")) {
          certificate_OPERATIONS_SCIENCE_INVENTORY_Total =
            certificate_OPERATIONS_SCIENCE_INVENTORY_Total + 1;
          if (
            course?.score !== "undefined" &&
            course.evaluationCompleted === true
          ) {
            completed_OPERATIONS_SCIENCE_INVENTORY =
              completed_OPERATIONS_SCIENCE_INVENTORY + 1;
          }
        }
        if (course?.certificates?.includes("OPERATIONS SCIENCE LEADERSHIP")) {
          certificate_OPERATIONS_SCIENTIST_Total =
            certificate_OPERATIONS_SCIENTIST_Total + 1;
          if (
            course?.score !== "undefined" &&
            course.evaluationCompleted === true
          ) {
            completed_OPERATIONS_SCIENTIST = completed_OPERATIONS_SCIENTIST + 1;
          }
        }
      });
      if (
        completed_OPERATIONS_SCIENCE_FLOW ===
          certificate_OPERATIONS_SCIENCE_FLOW_Total &&
        completed_OPERATIONS_SCIENCE_FLOW !== 0 &&
        certificate_OPERATIONS_SCIENCE_FLOW_Total !== 0
      ) {
        setoperationScienceFlowCertificate(true);
      }
      if (
        completed_OPERATIONS_SCIENCE_INVENTORY ===
          certificate_OPERATIONS_SCIENCE_INVENTORY_Total &&
        completed_OPERATIONS_SCIENCE_INVENTORY !== 0 &&
        certificate_OPERATIONS_SCIENCE_INVENTORY_Total != 0
      ) {
        setoperationScienceInventory(true);
      }
      if (
        completed_OPERATIONS_SCIENTIST ===
          certificate_OPERATIONS_SCIENTIST_Total &&
        completed_OPERATIONS_SCIENTIST !== 0 &&
        certificate_OPERATIONS_SCIENTIST_Total != 0
      ) {
        setoperationScientistCertificate(true);
      }
    }
  }, [courseName]);

  const generateCertificate = async (apiMethod, fileName, courseCompletionStatus) => {
    if (!courseCompletionStatus) {
      Swal.fire(
        "Download a certificate once all required courses and evaluations are completed"
      );
      return;
    }
    if (userName) {
      const confirmChange = await Swal.fire({
        title: "Confirmation",
        text: `The name on your certificate will be "${userName}" This is fixed for your account.`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });

      if (confirmChange.isConfirmed) {
        const response = await apiMethod(userName);
        if (response.status === 200) {
          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: "application/pdf" });

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element and set its attributes for download
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;

          // Append the link to the body, trigger the download, then remove the link
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    } else {
      Swal.fire({
        title: "Add name on your certificate",
        text: "Before you can download your certificate, click below to go to your user profile and enter your full name in the “Name on Certificate” field. This records the name you will see on any operations science certificate you may earn.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/EditProfile");
        }
      });
    }
  };

  return (
    <div className="CoursePreview">
      <UserHeader className="mb-4" cartCount={cartCount} />
      <br />
      <Container>
        <br />
        <Row>
          <Col sm={10}>
            <h3>MY COURSES</h3>
            <span>Following is the list of purchased courses.</span>
          </Col>
          <Col sm={2}>
            {" "}
            <Button onClick={handlenavigateManageCourse}>
              COURSE SELECTION
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card className="mt-4">
              <Card.Body className="card-body">
                <div className="table-responsive">

                </div>
                <Table striped bordered hover className="course-table">
                <thead>
                    <tr>
                      <th>
                        <h6>COURSE NAME</h6>
                      </th>
                      <th>
                        <h6 className="d-flex justify-content-center">
                          LESSONS COMPLETED
                        </h6>
                      </th>
                      <th>
                        <h6 className="d-flex justify-content-center">
                          COURSE STATUS
                        </h6>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {courses.length === 0 ? (
                      <tr>
                        <td colSpan="6">No courses available</td>
                      </tr>
                    ) : (
                      courseName?.map((course, index) =>
                        course.isPurchased ? (
                          <tr key={course.courseId}
                              onClick={() => handleCourseClick(course.id)} >
                            <td className="courseTitleLink">
                              <span
                                variant="link"
                              >
                                {course.courseTitle.toUpperCase()}
                              </span>
                            </td>
                            <td className="text-center">
                              {course.totalLessons === 0
                                ? "N/A"
                                : `${course.completedLesson} of ${course.totalLessons}`}
                            </td>
                            <td className="text-center">
                              {course.evaluationCompleted === true ? (
                                <Button
                                  className="CourseStarted"
                                  variant="success"
                                >
                                  COMPLETED
                                </Button>
                              ) : course.completedLesson > 0 ? (
                                <Button
                                  className="CourseStarted"
                                  variant="success"
                                >
                                  STARTED
                                </Button>
                              ) : (
                                <Button
                                  className="CourseNotStarted"
                                  variant="warning"
                                >
                                  NOT STARTED
                                </Button>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12}>
            <span>
              Below is the status of certificate completion. Once all courses
              for a certificate are completed, the Download button will turn
              green enabling download of the certificate.
            </span>
          </Col>
          <Col sm={6} className="mb-4">
            <Card className="mt-4">
              <Card.Body className="card-body">
                <h5>STATUS ICON LEGEND</h5>
                <ul class="no-bullets">
                  <li>
                    <FontAwesomeIcon icon={faCircle} /> Not purchased, required
                    for certificate
                  </li>
                  <li>
                    <Wrong /> Purchased not completed
                  </li>
                  <li>
                    <Tick /> Purchased and completed
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} className="mb-4 px-1">
            <Card className="mt-4">
              <Card.Body className="card-body"></Card.Body>
              <div className="table-responsive">
                <Table striped bordered hover className="course-table">
                  <thead>
                    <tr>
                      <th>
                        <h6>COURSE NAME</h6>
                      </th>
                      <th>
                        <h6 className="d-flex justify-content-center">
                          FLOW LEADERSHIP
                        </h6>
                      </th>
                      <th>
                        <h6 className="d-flex justify-content-center">
                          INVENTORY LEADERSHIP
                        </h6>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {courseName.length === 0 ? (
                      <tr className="TableCourseList">
                        <td colSpan="6">No courses available</td>
                      </tr>
                    ) : (
                      courseName.map((course) => (
                        <tr key={course.id} className="TableCourseList">
                          <td className="text-uppercase">
                            {course.courseTitle}
                          </td>
                          <td className="CourseCheckIcon">
                            {course?.certificates?.includes(
                              "FLOW LEADERSHIP"
                            ) &&
                              (course.isPurchased &&
                              course.evaluationCompleted === true ? (
                                <Tick />
                              ) : course.isPurchased ? (
                                <Wrong />
                              ) : (
                                <FontAwesomeIcon icon={faCircle} />
                              ))}
                          </td>
                          <td className="CourseCheckIcon">
                            {course?.certificates?.includes(
                              "INVENTORY LEADERSHIP"
                            ) &&
                              (course.isPurchased &&
                              course.evaluationCompleted === true ? (
                                <Tick />
                              ) : course.isPurchased ? (
                                <Wrong />
                              ) : (
                                <FontAwesomeIcon icon={faCircle} />
                              ))}
                          </td>

                        </tr>
                      ))
                    )}
                    <tr>
                      <td></td>
                      <td className="center-download-button">
                        <Button
                          className={
                            operationScienceFlowCertificate
                              ? ""
                              : "disabled-button-color"
                          }
                          onClick={() =>
                            generateCertificate(
                              apiService.generateCertificateFlowLeadership,
                              "certificate.pdf",
                              operationScienceFlowCertificate
                            )
                          }
                        >
                          DOWNLOAD CERTIFICATE
                        </Button>
                      </td>
                      <td className="center-download-button">
                        <Button
                          className={
                            operationScienceInventory
                              ? ""
                              : "disabled-button-color"
                          }
                          onClick={() =>
                            generateCertificate(
                              apiService.generateCertificateInventory,
                              "certificate.pdf",
                              operationScienceInventory
                            )
                          }
                        >
                          DOWNLOAD CERTIFICATE
                        </Button>
                      </td>

                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <div>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default UserMyCourses;
